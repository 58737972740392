import { useContext } from "react";
import { StoreContext } from "../../../Store/storeProvider";
import imgButton from "../../../../assets/imgPacks.svg";

export const useSampleData = (props) => {
  const iconButton = imgButton;
  const [store] = useContext(StoreContext);
  const items = [
    {
      number: ".01",
      width: "20%",
      title: "estrategia digital integral",
      text: store
        ? "We create a comprehensive communication plan tailored to your brand, combining data, analysis and creativity to achieve your goals. It includes a complete digital audit and a customized strategic planning."
        : "Creamos un plan integral de comunicación a medida para tu marca, combinando datos, análisis y creatividad para alcanzar tus objetivos. Incluye una auditoría digital completa y una planificación estratégica personalizada.",
    },
    {
      number: ".02",
      width: "20%",
      title: "social media",
      text: store
        ? "We design strategic content that captures attention, arouses emotions and converts those interactions into real results for your brand. How? Content plan, creative campaigns and dynamic Community Management."
        : "Diseñamos contenido estratégico que capta la atención, despierta emociones y convierte esas interacciones en resultados reales para tu marca. ¿Cómo? Plan de contenido, campañas creativas y Community Management dinámico.",
    },
    {
      number: ".03",
      width: "20%",
      title: "paid media",
      text: store
        ? "Investing is not enough; the important thing is to know where and how to do it. We create paid campaigns based on data and creativity to maximize your ROI in Google Ads, Display and networks. We constantly optimize, because A/B testing is our way of life."
        : "Invertir no es suficiente; lo importante es saber dónde y cómo hacerlo. Creamos campañas pagas basadas en datos y creatividad para maximizar tu ROI en Google Ads, Display y redes.",
    },
    {
      number: ".04",
      width: "10%",
      title: "seo",
      text: store
        ? "In a competitive digital environment, SEO is key to attract quality traffic. We optimize your Google presence with strategic blogging and monthly reports for sustainable growth."
        : "En un entorno digital competitivo, el SEO es clave para atraer tráfico de calidad. Optimizamos tu presencia en Google con blogs estratégicos e informes mensuales para un crecimiento sostenible.",
    },
    {
      number: ".05",
      width: "20%",
      title: "e-mail marketing",
      text: store
        ? "We specialize in creating valuable newsletters. Each campaign is designed to reach your audience at the right time, engage them with relevant content and, most importantly, convert that attention into action."
        : "Nos especializamos en crear newsletters de valor. Cada campaña está pensada para llegar a tu audiencia en el momento justo, enamorarla con contenido relevante y, lo más importante, convertir esa atención en acción.",
    },
  ];

  const itemsRowTop = [
    {
      number: ".01",
      id: 5,
      width: "20%",
      title: "estrategia digital integral",
      text: store
        ? "We create a comprehensive communication plan tailored to your brand, combining data, analysis and creativity to achieve your goals. It includes a complete digital audit and a customized strategic planning."
        : "Creamos un plan integral de comunicación a medida para tu marca, combinando datos, análisis y creatividad para alcanzar tus objetivos. Incluye una auditoría digital completa y una planificación estratégica personalizada.",
    },
    {
      number: ".02",
      width: "30%",
      id: 6,
      title: "social media",
      text: store
        ? "We design strategic content that captures attention, arouses emotions and converts those interactions into real results for your brand. How? Content plan, creative campaigns and dynamic Community Management."
        : "Diseñamos contenido estratégico que capta la atención, despierta emociones y convierte esas interacciones en resultados reales para tu marca. ¿Cómo? Plan de contenido, campañas creativas y Community Management dinámico.",
    },
    {
      number: ".03",
      width: "20%",
      id: 7,
      title: "paid media",
      text: store
        ? "Investing is not enough; the important thing is to know where and how to do it. We create paid campaigns based on data and creativity to maximize your ROI in Google Ads, Display and networks."
        : "Invertir no es suficiente; lo importante es saber dónde y cómo hacerlo. Creamos campañas pagas basadas en datos y creatividad para maximizar tu ROI en Google Ads, Display y redes.",
    },
  ];

  const itemsRowBottom = [
    {
      number: ".04",
      width: "30%",
      id: 8,
      title: "seo",
      text: store
        ? "In a competitive digital environment, SEO is key to attract quality traffic. We optimize your Google presence with strategic blogging and monthly reports for sustainable growth."
        : "En un entorno digital competitivo, el SEO es clave para atraer tráfico de calidad. Optimizamos tu presencia en Google con blogs estratégicos e informes mensuales para un crecimiento sostenible.",
    },
    {
      number: ".05",
      width: "20%",
      title: "e-mail marketing",
      text: store
        ? "We specialize in creating valuable newsletters. Each campaign is designed to reach your audience at the right time, engage them with relevant content and, most importantly, convert that attention into action."
        : "Nos especializamos en crear newsletters de valor. Cada campaña está pensada para llegar a tu audiencia en el momento justo, enamorarla con contenido relevante y, lo más importante, convertir esa atención en acción.",
    },
  ];

  return {
    iconButton,
    items,
    itemsRowBottom,
    itemsRowTop,
  };
};
