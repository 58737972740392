import { useState } from 'react';
import { useSampleData } from './sampleData';
import { useContext } from "react";
import { StoreContext } from "../../../Store/storeProvider";
import styles from './packs.module.css';
import Item from './item';

const Packs = ()=> {
    const [store] = useContext(StoreContext);

    const { iconButton, items, itemsRowBottom, itemsRowTop } = useSampleData()
    const [ openAccordion, setOpenAccordion ] = useState(null);

    const handleAccordion = accordionNumber => {
        setOpenAccordion(
            openAccordion === accordionNumber 
            ? null 
            : accordionNumber
        )
    }

    const title = store ? (
      <div className={styles.title}>
        <p>Our</p>
        <p className={styles.hubs}> hubs</p>
      </div>
    ) : (
      <div className={styles.title}>
        <p>Nuestros</p>
        <p className={styles.hubs}> hubs</p>
      </div>
    ); 


    return (
      <div className={styles.generalRoot}>
        <div className={styles.root}>
            {title}
          <div className={styles.items}>
            {items.map((item, index) => {
              return (
                <Item
                  item={item}
                  index={index}
                  iconButton={iconButton}
                  openAccordion={openAccordion}
                  handleAccordion={handleAccordion}
                  key={index}
                />
              );
            })}
          </div>
        </div>
        <div className={styles.rootDesktop}>
            {title}
          <div className={styles.items}>
            {itemsRowTop.map((item, index) => {
              return (
                <Item
                  item={item}
                  index={item.id}
                  iconButton={iconButton}
                  openAccordion={openAccordion}
                  handleAccordion={handleAccordion}
                  key={index}
                  isRow={true}
                />
              );
            })}
          </div>
          <div className={styles.items}>
            {itemsRowBottom.map((item, index) => {
              return (
                <Item
                  item={item}
                  index={index}
                  iconButton={iconButton}
                  openAccordion={openAccordion}
                  handleAccordion={handleAccordion}
                  key={index}
                  isRow={true}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
};

export default Packs;