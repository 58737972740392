import React,{ useContext } from "react";
import styles from "./marketing.module.css";
import Header from "../../Header/header";
import { StoreContext } from "../../Store/storeProvider";
import desktopImage from "../../../assets/desktopHome.svg";
import SocialNetworks from "../../HomePage/RenderHomePage/SocialNetworks/socialNetworks";
import CustomerLogos from "./CustomerLogos/customerLogos.js";
import WorkTogether from "../../WorkTogether/workTogether";
import landingMarketing from "../../../assets/landingMarketing.svg";
import Ubication from "../../Ubication/ubication";
import ContactForm from "../../ContactForm/contactForm";
import Footer from "../../Footer/footer";
import Packs from "./Packs/packs.js";
import Proyects from "./Proyects/proyects.js"

const Marketing = () => {

  const [store] = useContext(StoreContext);

  const subTitle = store
    ? "WE ARE A BOUTIQUE AGENCY"
    : "SOMOS UNA AGENCIA BOUTIQUE";

  const title = store ? (
    <p className={styles.title}>
      We provide communication<span>strategies 360°</span>
    </p>
  ) : (
    <p className={styles.title}>
      Brindamos estrategias de <span>comunicación 360°</span>{" "}
    </p>
  );

  return (
    <div className={styles.root}>
      <Header isEcommerce={true} />
      <div className={styles.container}>
        <div className={styles.socialNetworks}>
          <SocialNetworks />
        </div>
        <div className={styles.titleAndImage}>
          <div>
            <p className={styles.subTitle}>{subTitle}</p>
            {title}
          </div>
          <img
            src={desktopImage}
            alt="ecommerce icon"
            className={styles.desktopImage}
          />
          <div className={styles.homeImgDesktop}>
            <img src={landingMarketing} alt="ecommerce icon" />
          </div>
          <div className={styles.footerMobile}>
            <div className={styles.footerMobileText}>
              <p>
                Entendemos que cada marca tiene su propia historia y que no hay
                soluciones mágicas, solo estrategias personalizadas. Desde
                planificar campañas que atrapan hasta optimizar cada clic para
                que rinda al máximo, nos obsesionamos con los detalles para que
                vos puedas enfocarte en lo que mejor sabés hacer: liderar tu
                negocio.
              </p>
              <p>
                Por eso sumamos IP360 a IURCO: porque creemos que la
                comunicación 360° es clave para potenciar negocios digitales.
                ¿Nuestro secreto?
                <strong>La comunicación auténtica y genuina.</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Packs />
      <Proyects />
      <CustomerLogos />
      <WorkTogether />
      <div className={styles.contactFormAndUbication}>
        <div className={styles.contactFormRoot}>
          <ContactForm />
        </div>
        <Ubication />
      </div>
      <Footer isEcommercePage={true} />
    </div>
  );
};

export default Marketing;
