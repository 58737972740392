import React,{ useContext } from "react";
import styles from "./customerLogos.module.css";
import { useSampleData } from "../../../CustomerLogos/sampleData";
import { StoreContext } from "../../../Store/storeProvider";

const CustomerLogos = () => {
  const { data, dataDesktop1, dataDesktop2 } = useSampleData();
  const [store] = useContext(StoreContext);

  const title = store ? (
    <p className={styles.title}>
      Trust<span>us</span>
    </p>
  ) : (
    <p className={styles.title}>
      Confían en <span>nosotros</span>{" "}
    </p>
  );

  return (
    <div className={styles.root}>
      <div className={styles.titleRoot}>
        {title}
      </div>
      <div className={styles.container}>
        {data.map((item, index) => {
          return (
            <img
              className={
                item.name === "tiendaExo" ||
                item.name === "newsan" ||
                item.name === "movistar" ||
                item.name === "noblex" ||
                item.name === "redBull"
                  ? styles.imgExo
                  : styles.imgSize
              }
              src={item.img}
              alt="customerLogos"
              key={index}
            />
          );
        })}
      </div>
      <div className={styles.containerDesktop}>
        <div className={styles.containerDesktop1}>
          {dataDesktop1.map((item, index) => {
            return (
              <img
                className={
                  item.name === "unilever" || item.name === "quaker"
                    ? styles.imgSmall
                    : styles.imgSize
                }
                src={item.img}
                alt="customerLogos"
                key={index}
              />
            );
          })}
        </div>
        <div className={styles.containerDesktop2}>
          {dataDesktop2.map((item, index) => {
            return (
              <img
                className={
                  item.name === "tiendaExo" ? styles.imgExo : styles.imgSize
                }
                src={item.img}
                alt="customerLogos"
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CustomerLogos;
