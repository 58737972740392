import React, { useContext } from "react";
import { StoreContext } from "../../../Store/storeProvider";
import styles from './proyects.module.css'
import IndiaStyle from "../../../../assets/IndiaStyle.svg";
import rouge_logo from "../../../../assets/rouge_logo.svg";
import bangho_logo from "../../../../assets/bangho_logo.svg";
import depi4ever_logo from "../../../../assets/depi4ever_logo.svg";


const Proyects = () => {
  const [store] = useContext(StoreContext);

  const title = store ? (
    <div className={styles.title}>
      <p>Our </p>
      <p className={styles.titleBlue}>proyects</p>
    </div>
  ) : (
    <div className={styles.title}>
      <p>Algunos de </p>
      <p className={styles.titleBlue}> nuestros proyectos</p>
    </div>
  );

  return (
    <div className={styles.root}>
      {title}
      <div className={styles.gridContainer}>
        <div className={styles.project}>
          <img src={IndiaStyle} alt="IndiaStyle logo" />
          <div>
            <p>
              Optimización de campañas de performance para Indiastyle Outlet y
              estrategias de branding para Indiastyle Oficial.
            </p>
            <p>Resultado destacado:</p>
            <ul>
              <li>
                Incremento en tasa de conversión: +35,50% en solo 30 días.
              </li>
              <li>Crecimiento de ingresos: +61,57% en el mismo período.</li>
            </ul>
          </div>
        </div>
        <div className={styles.project}>
          <img src={depi4ever_logo} alt="Depi4ever logo" />
          <div>
            <p>
              Para Depi4ever, realizamos optimización mensual de campaña para
              motores de búsqueda y Google Adwords para la casa central y sus
              más de 60 sucursales.
            </p>
            <p>Resultado destacado:</p>
            <ul>
              <li>
                Incremento del 30% mensual de tráfico en período no estacional
                del servicio.
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.project}>
          <img src={rouge_logo} alt="Rouge logo" />
          <div>
            <p>
              Para Perfumerías Rouge llevamos a cabo las siguientes acciones:
            </p>
            <ul>
              <li>Campaña con influencers.</li>
              <li>Campaña masiva en Social Media: Facebook e Instagram.</li>
              <li>Creatividad e Implementación de Campaña Digital.</li>
              <li>Activaciones en PDV.</li>
            </ul>
          </div>
        </div>
        <div className={styles.project}>
          <img src={bangho_logo} alt="Bangho logo" />
          <div>
            <p>
              Para Bangho, nos enfocamos en la optimización mensual de campaña
              para motores de búsqueda.
            </p>
            <p>Resultado destacado:</p>
            <ul>
              <li>Disminución de un 25% en tasa de rebote y 36% de salidas.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Proyects;
